<template>
    <div v-if="isVisible">
      <h2>Usuarios Registrados {{ month ? 'por Día' : 'por Año y Mes' }}</h2>
      <BarChart :chartData="chartData" :options="options" />
    </div>
  </template>
  
  <script>
  import { BarChart } from 'vue-chart-3';
  import { getUsersRegistrationsByYearMonthWeek } from './UsersRegistrationsByYearMonthWeekService';
  import { mapState } from 'vuex';
  
  export default {
    name: 'UsersRegistrationsByYearMonthWeek',
    components: {
      BarChart
    },
    props: {
      year: String,
      month: String
    },
    data() {
      return {
        chartData: null,
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              title: {
                display: true,
                text: 'Fecha'
              }
            },
            y: {
              title: {
                display: true,
                text: 'Total de registros'
              },
              beginAtZero: true
            }
          }
        },
      };
    },
    computed: {
      ...mapState({
        isVisible: state => state.paneles.user.find(p => p.name === 'UsersRegistrationsByYearMonthWeek').visible
      }),
    },
    methods: {
      async fetchData() {
        try {
          const data = await getUsersRegistrationsByYearMonthWeek(this.year, this.month);
          this.chartData = {
            labels: data.labels,
            datasets: [{
              label: this.month ? 'Registros totales por dia' : 'Total de registros por mes',
              backgroundColor: '#3C1053',
              data: data.values,
            }],
          };
        } catch (error) {
          console.error('Failed to fetch data', error);
        }
      }
    },
    watch: {
      year(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.fetchData();
        }
      },
      month(newVal, oldVal) {
        if (newVal !== oldVal) {
          this.fetchData();
        }
      },
      isVisible(newVal) {
        if (newVal) {
          this.fetchData();
        }
      }
    },
    mounted() {
      if (this.isVisible) {
        this.fetchData();
      }
    },
  };
  </script>
  
  <style scoped>
  /* Estilos adicionales si es necesario */
  </style>
  