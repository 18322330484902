<template>
    <div class="dashboard-usuarios">
      <h1>Panel de Usuarios</h1>
      <div v-if="roleLoaded">
        <ListaDeUsuarios v-if="canView('administrador')" />
        <Forbidden v-else />
      </div>
      <div v-else>
        <p>Cargando...</p>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import ListaDeUsuarios from '@/components/administracion/ListaDeUsuarios.vue';
  import Forbidden from '@/components/Forbidden.vue';
  
  export default {
    name: 'DashboardUsuarios',
    components: {
      ListaDeUsuarios,
      Forbidden
    },
    data() {
      return {
        role: null,
        roleLoaded: false
      };
    },
    computed: {
      userName() {
        return this.$store.state.user ? this.$store.state.user.username : 'Invitado';
      },
      roleToken() {
        return this.$store.state.roleToken;
      }
    },
    watch: {
        roleToken(newToken) {
            if (newToken) {
            this.validateRole();
            }
        }
    },
    methods: {
      async validateRole() {
        console.log("Role token:", this.roleToken);

        if (!this.roleToken) {
          this.roleLoaded = true;
          return;
        }
        try {
          const response = await axios.post('/api/verify-role', {
            roleToken: this.roleToken
          });
          console.log('Role response:', response.data); // Agregar un log para depuración
          this.role = response.data.role;
        } catch (error) {
          console.error('Error verifying role:', error);
          this.role = null;
        } finally {
          this.roleLoaded = true;
        }
      },
      canView(expectedRole) {
        if (!this.roleLoaded) {
            return false;
        }
        const roleHierarchy = {
            'usuario': 1,
            'administrador': 2,
            'superadministrador': 3
        };

        return roleHierarchy[this.role] >= roleHierarchy[expectedRole];
      },
    },
    mounted() {
      this.validateRole(); // Llamar a la función directamente en lugar de usar async/await aquí
    }
  }
  </script>
  
  <style scoped>
  .dashboard-usuarios {
    padding: 20px;
  }
  </style>
  