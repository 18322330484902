<template>
    <div class="contenedor-panel">
      
      <h2>Hola {{ userName }}</h2>
      <div class="botones">
        <a href="/dashboard/general-metrics">Métricas Generales</a>
        <a href="/dashboard/event-metrics">Métricas por Evento</a>
        <a href="/dashboard/user-metrics">Métricas Usuarios</a>
      </div>
      <div class="buscadores-configuraciones">
        <div class="buscador">
          <p>Busca un usuario</p>
          <SearchUser></SearchUser>
        </div>
        <div class="buscador">
          <p>Busca un evento</p>
          <SearchEvent></SearchEvent>
        </div>
        <div class="configuracion">
          <p>Configuraciones</p>
          <button class="gear-button" @click="emitOpenModalEvent">Ajusta la visibilidad</button>
        </div>
      </div>
      <div v-if="roleLoaded" class="botones">
        <a v-if="canView('usuario')" :href="profileEditUrl">Editar mi perfil</a>
        <a v-if="canView('administrador')" href="/dashboard/usuarios">Lista de usuarios</a>
        <a v-if="canView('superadministrador')" href="/dashboard/regiones">Configurar regiones</a>
      </div>
      <div v-else class="botones">
        <p>Cargando...</p>
      </div>
      <div class="botones">
        <button @click="logout">Cerrar sesión</button>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import SearchUser from '@/components/search/SearchUser.vue';
  import SearchEvent from '@/components/search/SearchEvent.vue';
  
  export default {
    name: 'AccessPanel',
    components: {    
      SearchUser,
      SearchEvent
    },
    data() {
      return {
        role: null,
        roleLoaded: false
      }
    },
    watch: {
        roleToken(newToken) {
            if (newToken) {
            this.validateRole();
            }
        }
    },

    computed: {
      userName() {
        return this.$store.state.user ? this.$store.state.user.username : 'Invitado';
      },
      roleToken() {
        return this.$store.state.roleToken;
      },
      profileEditUrl() {
        // Asegúrate de que user existe antes de acceder a token
        return this.$store.state.user ? `/dashboard/user/edit/${this.$store.state.user.token}` : '#';
      }
    },
    methods: {
      async validateRole() {
        console.log("Role token:", this.roleToken);

        if (!this.roleToken) {
          this.roleLoaded = true;
          return;
        }
        try {
          const response = await axios.post('/api/verify-role', {
            roleToken: this.roleToken
          });
          console.log('Role response:', response.data); // Agregar un log para depuración
          this.role = response.data.role;
        } catch (error) {
          console.error('Error verifying role:', error);
          this.role = null;
        } finally {
          this.roleLoaded = true;
        }
      },
      canView(expectedRole) {
        if (!this.roleLoaded) {
            return false;
        }
        const roleHierarchy = {
            'usuario': 1,
            'administrador': 2,
            'superadministrador': 3
        };

        return roleHierarchy[this.role] >= roleHierarchy[expectedRole];
    },

      emitOpenModalEvent() {
        this.$emit('openModal');
      },
      logout() {
        this.$store.dispatch('logout');
        const redirect = '/';
        this.$router.push(redirect);
      }
    },
    mounted() {
      this.validateRole(); // Llamar a la función directamente en lugar de usar async/await aquí
    }
  }
  </script>
  
  <style lang="sass">
  .contenedor-panel 
    padding: 40px 0 
    max-width: 1400px
    margin: 0 auto
    display: flex
    flex-wrap: wrap
    h2
      text-align: center
      width: 100%
    .botones
      width: 100%
      margin-top: 40px
      display: flex
      justify-content: center
      a            
        text-decoration: none
        margin-top: 15px
        display: block
        margin: 5px 10px
        border: none
        padding: 10px 15px
        text-align: center            
        cursor: pointer
        font-size: 16px
        transition: background-color 0.3s, color 0.3s
        width: 100% 
        max-width: 350px        
        background: #fff
        box-shadow: 5px 5px 5px rgba(155,155,155, 0.8)
        color: #000
        &:hover
          background: #000
          color: #fff     
      button               
        text-decoration: none
        margin-top: 15px
        display: block
        margin: 5px auto
        border: none
        padding: 10px 15px
        text-align: center            
        cursor: pointer
        font-size: 16px
        transition: background-color 0.3s, color 0.3s
        width: 100% 
        max-width: 350px        
        background: #fff
        box-shadow: 5px 5px 5px rgba(155,155,155, 0.8)
        color: #000
        &:hover
          background: #000
          color: #fff                   
  .buscadores-configuraciones
    padding: 40px 0 
    max-width: 1400px
    width: 100%    
    margin: 0 auto
    display: flex
    flex-wrap: wrap
    justify-content: center
    .buscador, .configuracion
      width: 100%
      max-width: 350px
      margin: 20px 10px
      display: flex
      flex-wrap: wrap
      flex-direction: column        
      p
        margin-bottom: 10px
      button               
        text-decoration: none
        margin-top: 15px
        display: block
        margin: 5px auto
        border: none
        padding: 10px 15px
        text-align: center            
        cursor: pointer
        font-size: 16px
        transition: background-color 0.3s, color 0.3s
        width: 100% 
        max-width: 350px        
        background: #fff
        box-shadow: 5px 5px 5px rgba(155,155,155, 0.8)
        color: #000
        &:hover
          background: #000
          color: #fff            
      input
        padding: 12px 15px
        margin: 23px 0 5px 0
  </style>
  