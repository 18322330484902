<template>
    <div>
        <h1>Regiones y países</h1>
        <HomeButton :isNotDashboardRoot="isNotDashboardRoot" />
        <AddRegionButton ref="addRegionButton" @refreshRegions="fetchRegions"></AddRegionButton>
        <table class="styled-table">
            <thead>
                <tr>
                    <th>Etiqueta</th>
                    <th>Países</th>
                    <th>Acciones</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="region in regions" :key="region.id_region">
                    <td>{{ region.name }}</td>
                    <td>{{ parseCountries(region.countries).join(', ') }}</td>
                    <td>
                        <button @click="openEditModal(region)">Editar</button>
                        <button @click="deleteRegion(region.id_region)">Borrar</button>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
import axios from 'axios';
import AddRegionButton from './regiones/AddRegionButton.vue';
import HomeButton from '@/components/botones/HomeButton.vue';

export default {
    components: {
        AddRegionButton,
        HomeButton
    },
    data() {
        return {
            regions: [],
            isNotDashboardRoot: true
        };
    },
    methods: {
        fetchRegions() {
            axios.get('/api/regions').then(response => {
                this.regions = response.data.map(region => ({
                    ...region,
                    countries: JSON.parse(region.countries)
                }));
            });
        },
        parseCountries(countries) {
            if (typeof countries === 'string') {
                return JSON.parse(countries);
            }
            return countries;
        },
        openEditModal(region) {
            this.$refs.addRegionButton.openModalWithRegion(region); // Llama a un método en AddRegionButton
        },
        deleteRegion(id) {
            axios.post(`/api/regions/delete/${id}`).then(response => {
                if (response.data.success) {
                    this.fetchRegions();
                    alert('Región eliminada correctamente');
                } else {
                    alert('Error al eliminar la región');
                }
            }).catch(error => {
                console.error('Error deleting region:', error);
                alert('Hubo un problema al eliminar la región.');
            });
        }
    },
    mounted() {
        this.fetchRegions();
    }
}
</script>



  <style scoped>
  .styled-table {
    width: 100%;
    border-collapse: collapse;
    margin: 25px 0;
    /* font-size: 0.9em; */
    min-width: 400px;
    border-radius: 5px 5px 0 0;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0,0,0,0.15);
  }
  
  .styled-table thead tr {
    background-color: #1b1b1b;
    color: #ffffff;
    text-align: left;
    font-weight: bold;
  }
  
  .styled-table th,
  .styled-table td {
    padding: 12px 30px;
    text-align: left;
  }
  
  .styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
  }
  
  .styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }
  
  .styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #1b1b1b;
  }
  
  .styled-table tbody tr.active-row {
    font-weight: bold;
    color: #1b1b1b;
  }
  
  .document-button, .links a {
    background-color: #1b1b1b;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
    text-decoration: none;
    font-size: 0.8em;
    margin-right: 10px;
  }
  
  .document-button:hover {
    background-color: #002fb2;
  }

  button {
    margin-top: 15px;
    display: inline-block;
    margin: 5px 10px;
    border: none;
    border-radius: 5px;
    padding: 4px 15px;
    text-align: center;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.3s, color 0.3s;
    /* width: 100% ; */
    max-width: 150px;
    background: #000;
    color: #fff;
}
    button:hover{

        background: #8246AF;
        color: #fff;
    }

  </style>
  