<template>
  <div id="app-g">    
    <main class="contenedor-general">
      <router-view/>
    </main>
  </div>
</template>
<script>
export default {
  name: 'App',
  created() {
    this.$store.dispatch('initializeState');
  },
};
</script>
<style lang="sass">
* 
  -webkit-box-sizing: border-box
  -moz-box-sizing: border-box
  box-sizing: border-box 

html 
  box-sizing: border-box
  font-size: 62.5% 

*, *:before, *:after 
  box-sizing: inherit
  margin: 0
  padding: 0 
#app 
  font-family: Avenir, Helvetica, Arial, sans-serif
  -webkit-font-smoothing: antialiased
  -moz-osx-font-smoothing: grayscale
  text-align: center
  color: #2c3e50


nav 
  padding: 30px
  a 
    font-weight: bold
    color: #2c3e50
    &.router-link-exact-active 
      color: #42b983
    
.contenedor-general
  max-width: 1400px
  width: 95%
  margin: 0 auto
  padding: 30px 0
  display: flex
  align-items: flex-start
  justify-content: center
  min-height: 90vh 

  > div:first-child
    width: 100%
select 
  padding: 8px 10px
  border: none
  width: 80vw
  max-width: 300px
  margin: 5px 10px
  border-radius: 5px
canvas 
  max-height: 428px!important

</style>
