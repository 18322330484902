<template>
  <div v-if="isVisible">
    <h2>Total de eventos por año y mes</h2>
    <BarChart :chartData="chartData" :options="options" />
  </div>
</template>

<script>
import { BarChart } from 'vue-chart-3';
import { geteventsCountByYearMonth } from './eventsCountByYearMonthService';
import { mapState } from 'vuex';

export default {
  name: 'eventsCountByYearMonthEvent',
  components: {
    BarChart
  },
  props: {
    year: String,
    month: String
  },
  data() {
    return {
      chartData: null,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            title: {
              display: true,
              text: ' '
            }
          },
          y: {
            title: {
              display: true,
              text: 'Total de eventos'
            },
            beginAtZero: true
          }
        }
      },
    };
  },
  computed: {
    ...mapState({
      isVisible: state => state.paneles.event.find(p => p.name === 'eventsCountByYearMonthEvent').visible
    }),
  },
  methods: {
    async fetchData() {
      try {
        const data = await geteventsCountByYearMonth(this.year, this.month);
        this.chartData = {
          labels: data.labels,
          datasets: [{
            label: 'Total de eventos por mes',
            backgroundColor: '#3C1053',
            data: data.values,
          }],
        };
      } catch (error) {
        console.error('Failed to fetch data', error);
      }
    }
  },
  watch: {
    year(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchData();
      }
    },
    month(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchData();
      }
    },
    isVisible(newVal) {
      if (newVal) {
        this.fetchData();
      }
    }
  },
  mounted() {
    if (this.isVisible) {
      this.fetchData();
    }
  },
};
</script>

<style scoped>
/* Estilos adicionales si es necesario */
</style>
