<template>
    <div class="contenedor-form-general">        
        <HomeButton :isNotDashboardRoot="isNotDashboardRoot" />
        <div class="contenedor-form">
            <p class="font-bold titulo">Crear cuenta en el grupo</p>
            <form @submit.prevent="handleRegister">
                <div class="correo">
                    <span>Nombre</span>
                    <input v-model="nombre" type="text" placeholder="Nombre y apellido">
                </div>
                <div class="correo">
                    <span>Dirección de email</span>
                    <input v-model="email" type="email" placeholder="ejemplo@mail.com">
                </div>
                <div class="correo">
                    <span>Contraseña</span>
                    <input v-model="password" type="password" placeholder="Debe tener al menos 7 caracteres">
                </div>
                <div class="correo">
                    <span>Confirmar contraseña</span>
                    <input v-model="confirmPassword" type="password" placeholder="">
                </div>
                <div class="correo">
                    <span>Rol</span>
                    <select v-model="role" required>
                        <option value="">-- Selecciona un rol --</option>
                        <option v-for="option in availableRoles" :value="option.value" :key="option.value">{{ option.text }}</option>
                    </select>
                </div>
                <button type="submit">Crear usuario</button>
            </form>            
        </div>
        <div class="contenedor-form">
            <p class="font-bold titulo">Crear link de registro</p>
            <form class="form-claves" @submit.prevent="handleCreateKey">
                <div class="correo">
                    <span>Rol</span>
                    <select v-model="selectedRoleKey" required>
                        <option value="">-- Selecciona un rol --</option>
                        <option v-for="option in availableRoles" :value="option.value" :key="option.value">{{ option.text }}</option>
                    </select>
                </div>
                <button type="submit">Generar clave</button>
            </form>

            <!-- Tabla para mostrar los enlaces generados -->
            <table v-if="keys.length > 0" class="styled-table">
                <thead>
                    <tr>
                        <th>Clave</th>
                        <th>Permiso</th>
                        <th>Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="key in keys" :key="key.id_token_clave">
                        <td><a :href="`https://dashboard.abbvie.bmotik.com/registrarse/${key.id_token_clave}`" target="_blank">{{key.id_token_clave}}</a></td>
                        <td>{{ key.rol }}</td>
                        <td>
                            <button @click="deleteKey(key.id_token_clave)">Eliminar</button>
                        </td>
                    </tr>
                </tbody>
            </table>
            <p v-else>No hay claves generadas.</p>
        </div>
        <div class="caja-blanca">
            
            <button @click="goToListUser">Volver</button>

        </div>
    </div>
</template>

<script>
import HomeButton from '@/components/botones/HomeButton.vue';
import axios from 'axios';

export default {
    props: ['groupId'],
    data() {
        return {
            nombre: '',
            email: '',
            password: '',
            confirmPassword: '',
            role: '',
            availableRoles: [],
            keys: [],
            selectedRoleKey: '',
            isNotDashboardRoot: true,
        };
    },
    components: {
        HomeButton,
    },
    watch: {
        roleToken(newToken) {
            if (newToken) {
            this.validateRole();
            }
        }
    },
    computed: {      
      roleToken() {
        return this.$store.state.roleToken;
      }
    },
    created() {
        this.setAvailableRoles();
        this.fetchKeys();
    },
    methods: {
        handleRegister() {
            if (this.password !== this.confirmPassword) {
                alert("Las contraseñas no coinciden.");
                return;
            }

            axios.post('/api/register', {
                nombre: this.nombre,
                email: this.email,
                password: this.password,
                groupId: this.groupId,
                role: this.role
            }).then(response => {
                if (response.data.success) {
                    alert('Registro exitoso');
                    this.$router.push('/dashboard/usuarios'); // Navega de vuelta a la lista de usuarios
                } else {
                    alert(response.data.error);
                }
            }).catch(error => {
                console.error('Error de registro:', error);
                alert('Hubo un problema con tu solicitud de registro.');
            });
        },
        handleCreateKey() {
            axios.post('/api/create-key', {
                groupId: this.groupId,
                role: this.selectedRoleKey
            }).then(response => {
                if (response.data.success) {
                    alert('Clave generada exitosamente');
                    this.keys.push(response.data.key);
                } else {
                    alert(response.data.error);
                }
            }).catch(error => {
                console.error('Error generando clave:', error);
                alert('Hubo un problema con la generación de la clave.');
            });
        },
        fetchKeys() {
            axios.get(`/api/keys/${this.groupId}`).then(response => {
                this.keys = response.data.keys;
            }).catch(error => {
                console.error('Error fetching keys:', error);
            });
        },
        deleteKey(token) {
            axios.post('/api/delete-key', { token }).then(response => {
                if (response.data.success) {
                    // Filtra la clave eliminada basándote en su token
                    this.keys = this.keys.filter(key => key.id_token_clave !== token);
                    alert('Clave eliminada con éxito');
                } else {
                    alert('Error al eliminar la clave');
                }
            }).catch(error => {
                console.error('Error eliminando clave:', error);
                alert('Error al eliminar la clave');
            });
        },

        async setAvailableRoles() {
            
            console.log("Role token:", this.roleToken);
            console.log("Token del grupo:", this.groupId);

            if (!this.roleToken) {
                this.roleLoaded = true;
                return;
            }
            try {
            const response = await axios.post('/api/verify-role', {
                roleToken: this.roleToken
            });
                console.log('Role response:', response.data); // Agregar un log para depuración
                this.roleAuth = response.data.role;
                if (this.roleAuth === 'superadministrador') {
                this.availableRoles = [
                    { value: 'superadministrador', text: 'Superadministrador' },
                    { value: 'administrador', text: 'Administrador' },
                    { value: 'usuario', text: 'Usuario' }
                ];
                } else if (this.roleAuth === 'administrador') {
                    this.availableRoles = [
                        { value: 'administrador', text: 'Administrador' },
                        { value: 'usuario', text: 'Usuario' }
                    ];
                }
            } catch (error) {
                console.error('Error verifying role:', error);
                this.roleAuth = null;
            } finally {
                this.roleLoaded = true;
            }
            
            // Este método determina qué roles están disponibles basándose en el usuario que crea el nuevo usuario
            
        },
        goToListUser() {
            this.$router.push('/dashboard/usuarios');
        },
    }
}
</script>


<style lang="sass" scoped >
.contenedor-form-general
    width: 100%
    display: flex
    max-width: 1200px
    align-items: center
    // flex-direction: column
    justify-content: center
    height: 100%
    align-items: stretch
    flex-wrap: wrap
    .contenedor-form 
        width: 100%!important
        @media screen and (min-width: 350px)
        @media screen and (min-width: 592px)
        @media screen and (min-width: 780px)
        @media screen and (min-width: 992px)
        @media screen and (min-width: 1200px)
            width: 48%!important
        margin: 0 auto
    .caja-blanca
        width: 100%
    .contenedor-form, .caja-blanca
        padding: 20px 15px 15px 15px
    
        max-width: 529px
        background: #fff
        // border-radius: 20px
        margin-top: 20px
        .titulo
            font-size: 32px
            text-align: center
            margin-bottom: 20px
        form 
            position: relative
            text-align: left
            .correo, .password
                span
                    display: block
                    width: 100%
                    margin: 0
                    padding: 0
                    font-size: 20px
                    font-weight: bold
                    margin: 25px 0 0 0
                input
                    width: 100%
                    border: none
                    padding: 10px 15px
                    // border-radius: 5px
                    background: #fff
                    border-bottom: 2px solid #000
                    transition: all 0.2s
                    margin-bottom: 20px
                    &:focus, &:active                                                
                      outline: none
                      border: none!important
                      border-bottom: 2px solid #999!important
            button
                background: #000
                color: #fff
                margin-top: 15px
                font-size: 0.8em
                text-align: center
            .password
                margin-top: 15px
        button
            display: block
            width: 100%
            border: none            
            padding: 15px
            text-align: left            
            cursor: pointer
            font-size: 16px
        .terminos, .pass-olvidado
            font-size: 0.7em
            max-width: 100%
            margin: 20px auto
            a
                color: #555
                text-decoration: none   
                cursor: pointer
                transition: all 0.2s
                font-weight: bold
                text-decoration: underline
                &:hover
                  color: #000
        .pass-olvidado
            display: flex
            align-items: center
            svg
                transform: scale(0.9)
                margin-left: 5px        
    .caja-blanca
        button
            background: #000
            color: #fff
            text-align: center
/* Animaciones */
// .slide-fade-enter-active, .slide-fade-leave-active
//     transition: all 0.2s ease

// .slide-fade-enter, .slide-fade-leave-to /* Inicio y fin de estado para enter/leave */
//     transform: translateY(10px)
//     opacity: 0

.fade-enter-active, .fade-leave-active
    transition: opacity 0.2s ease
.fade-enter, .fade-leave-to
    opacity: 0
.move-up-enter-active, .move-up-leave-active
    transition: transform 0.2s ease, opacity 0.2s ease
.move-up-enter, .move-up-leave-to
    transform: translateY(-20px)
    opacity: 0
.slide-fade-enter-active, .slide-fade-leave-active
    transition: all 0.2s ease
.slide-fade-enter, .slide-fade-leave-to
    transform: translateY(10px)
    opacity: 0
.email-placeholder
    cursor: pointer
    position: absolute
    line-height: 0
    opacity: 0
    // top: 0
    // display: none
    height: 0
    transition: all 0.2s
.email-placeholder.active
    // display: block
    position: relative
    height: auto
    line-height: 1
    opacity: 1
.password
    opacity: 0
.password.pass-active
    opacity: 1
.logo 
  width: fit-content
  margin: 20px
  padding: 10px
  font-size: 24px 
  font-family: Arial, Helvetica, sans-serif
  font-weight: bold
  text-align: right
  line-height: 0.6

select 
    width: 100%
    max-width: 100%
    margin: 10px auto 20px auto
    border: 2px solid #000


.styled-table 
    width: 100%
    border-collapse: collapse
    margin: 25px 0
    min-width: 400px
    border-radius: 5px 5px 0 0
    overflow: hidden
    box-shadow: 0 0 20px rgba(0,0,0,0.15)


.styled-table thead tr 
    background-color: #1b1b1b
    color: #ffffff
    text-align: left
    font-weight: bold


.styled-table th, .styled-table td 
    text-align: left
.styled-table th
    padding: 12px 30px
.styled-table td 
    padding: 0 20px


.styled-table tbody tr 
    border-bottom: 1px solid #dddddd


.styled-table tbody tr:nth-of-type(even) 
    background-color: #f3f3f3


.styled-table tbody tr:last-of-type 
    border-bottom: 2px solid #1b1b1b


.styled-table tbody tr.active-row 
    font-weight: bold
    color: #1b1b1b

.styled-table button 
    padding: 5px 15px!important
    margin: 5px 0
    background: #000
    color: #fff
    text-align: center!important
    transition: all 0.2s
    &:hover
        background: #a90101
        
        

.button, .links a 
    background-color: #1b1b1b
    color: white
    border: none
    padding: 8px 12px
    border-radius: 4px
    cursor: pointer
    transition: background 0.3s ease
    text-decoration: none
    font-size: 0.8em
    margin-right: 10px


.button:hover 
    background-color: #002fb2
.form-claves 
    padding: 1px 20px 20px 20px
    border-radius: 5px
    background: #e0e0e0
</style>