import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
  state: {
    isLoggedIn: false,
    filters: {
      general: {
        dateRange: 'year',
        customFilters: {},
      },
      event: {
        dateRange: 'year',
        selectedEvent: null,
      },
      user: {
        searchQuery: '',
      },
      year: null,
      month: null
    },
    paneles: {
      general: [
        { name: 'eventsCountByYearMonth', visible: true },        
        { name: 'eventsCountByLocation', visible: true },
      ],
      event: [
        { name: 'eventsCountByYearMonthEvent', visible: true },
      ],
      user: [
        { name: 'UsersRegistrationsByYearMonthWeek', visible: true},
        { name: 'UsersDistributionByLocation', visible: true},
      ],
    },
    isFirstLoad: true,
    user: null,
    isAuthenticated: false,
    roleToken: '', // Default role
  },
  mutations: {
    setLoginState(state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },
    setFilter(state, { type, filter }) {
      state.filters[type] = filter;
    },
    togglePanel(state, { panelType, panelName }) {
      const panel = state.paneles[panelType].find(p => p.name === panelName);
      if (panel) {
        panel.visible = !panel.visible;
      }
    },
    setYear(state, year) {
      state.filters.year = year;
    },
    setMonth(state, month) {
      state.filters.month = month;
    },
    setFirstLoad(state, isFirstLoad) {
      state.isFirstLoad = isFirstLoad;
    },
    updateAllPanels(state, newPanels) {
      state.paneles = newPanels;
    },
    applyFilters({ commit }, { year, month }) {
      commit('setYear', year);
      commit('setMonth', month);
    },
    setUser(state, userData) {
      state.user = userData;
      state.isAuthenticated = true;
      state.isLoggedIn = true; // Asegura que esto también se establezca
      state.roleToken = userData.role; // Set user role
    },
    clearUser(state) {
      state.user = null;
      state.isAuthenticated = false;
      state.isLoggedIn = false; // Actualiza isLoggedIn aquí
      state.roleToken = ''; // Reset role to default
    },
  },
  actions: {
    login({ commit }, user) {
      commit('setUser', user);
      commit('setLoginState', true); // Asegúrate de que también se establece isLoggedIn
      // this.$router.push('/dashboard');
    },
    logout({ commit }) {
      commit('clearUser'); // Limpia el usuario y otros estados relevantes
      commit('setLoginState', false); // Establece el estado de inicio de sesión como falso
      // this.$router.push('/'); // Redirige al usuario a la página de inicio de sesión
    },
    setFilter({ commit }, payload) {
      commit('setFilter', payload);
    },
    togglePanel({ commit }, { panelType, panelName }) {
      commit('togglePanel', { panelType, panelName });
    },
    initializeState({ commit, state }) {
      if (state.isFirstLoad) {
        commit('setFirstLoad', false);
      }
    },
    updateAllPanels({ commit }, newPanels) {
      commit('updateAllPanels', newPanels);
    }
  },  
  plugins: [
    createPersistedState({
      paths: ['isLoggedIn', 'filters', 'paneles', 'isFirstLoad', 'user', 'isAuthenticated', 'roleToken'], // Agrega 'roleToken' aquí
    }),
  ],
  
});
