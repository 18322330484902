import { createRouter, createWebHistory } from 'vue-router';
import store from '../store';
import UserLogin from '../views/UserLogin.vue';
import Registrarse from '../views/public/Registrarse.vue';
import UserDashboard from '../views/UserDashboard.vue';
import Forbidden from '../components/Forbidden.vue';
// import GeneralMetrics from '../components/metrics/GeneralMetrics.vue';
// import EventMetrics from '../components/metrics/EventMetrics.vue';
// import UserMetrics from '../components/metrics/UserMetrics.vue';
import DashboardUsuarios from '@/views/administracion/DashboardUsuarios.vue'; // Asegúrate de importar tu componente
import AgregarUsuario from '@/components/administracion/AgregarUsuario.vue'; // Asegúrate de importar tu componente
import EditUser from '../views/administracion/EditUser.vue'; // Asegúrate de tener el path correcto
import Regiones from '../views/configuraciones/Regiones.vue'; // Asegúrate de tener el path correcto



const routes = [
  {
    path: '/',
    name: 'UserLogin',
    component: UserLogin,
  },
  {
    path: '/dashboard',
    name: 'UserDashboard',
    component: UserDashboard,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'general-metrics',
        name: 'GeneralMetrics',  // Asegúrate de tener el atributo name aquí
        // component: GeneralMetrics,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'event-metrics',
        name: 'EventMetrics',
        // component: EventMetrics,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: 'user-metrics',
        name: 'UserMetrics',
        // component: UserMetrics,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: '/dashboard/user/edit/:token', // Asegúrate de que el token es el parámetro correcto que necesitas
    name: 'EditUser',
    component: EditUser,
    props: true // Esto permite que el token se pase como una prop al componente
  },
  {
    path: '/dashboard/regiones', // Asegúrate de que el token es el parámetro correcto que necesitas
    name: 'Regiones',
    component: Regiones,
    props: true // Esto permite que el token se pase como una prop al componente
  },
  {
    path: '/user-details/:userId',
    name: 'UserDetails',
    component: () => import('../components/metrics/UserMetrics/DetaillUser.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/event-details/:eventId',
    name: 'EventDetails',
    component: () => import('../components/metrics/EventMetrics/DetaillEvent.vue'),
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/dashboard/usuarios/agregar-usuario/:groupId',
    name: 'AgregarUsuario',
    component: AgregarUsuario,
    props: true,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/registrarse/:clave?',
    name: 'Registrarse',
    component: Registrarse,
    props: route => ({ claveProp: route.params.clave }) // Aquí pasas el parámetro como prop al componente
},
  {
    path: '/403',
    name: 'Forbidden',
    component: Forbidden
  },
  {
    path: '/dashboard/usuarios',
    name: 'DashboardUsuarios',
    component: DashboardUsuarios,
    meta: { requiresAuth: true }  // Sólo asegura que el usuario debe estar autenticado para acceder
  }
];


const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.isLoggedIn) {
      next('/');
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
