<template>
  <div class="content">
    <h1>Métricas Generales</h1>
    <!-- Selectores para Año y Mes -->
    <div>
      <select v-model="selectedYear">
        <option disabled value="">Seleccione un año</option>
        <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
      </select>
      <select v-model="selectedMonth">
        <option value="">Todos los meses</option>
        <option v-for="month in 12" :key="month" :value="month">{{ month }}</option>
      </select>
      <!-- <button @click="applyFilters">Aplicar</button> -->
    </div>
    <div v-for="panelName in visiblePanelNames" :key="panelName">
      <component :is="getComponentName(panelName)" :year="selectedYear" :month="selectedMonth" />
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import eventsCountByYearMonth from './GeneralMetrics/eventsCountByYearMonth/eventsCountByYearMonth.vue';
import eventsCountByLocation from './GeneralMetrics/eventsCountByLocation/eventsCountByLocation.vue';

export default {
  components: {
    eventsCountByYearMonth,
    eventsCountByLocation,
  },
  props: ['isModalOpen'],
  data() {
    return {
      selectedYear: '',
      selectedMonth: '',
      years: [2021, 2022, 2023, 2024]
    };
  },
  computed: {
    ...mapState(['paneles']),
    visiblePanelNames() {
      return this.paneles.general.filter(p => p.visible).map(p => p.name);
    },
  },
  methods: {
    ...mapActions(['togglePanel', 'applyFilters']),
    applyFilters() {
      this.$store.dispatch('applyFilters', { year: this.selectedYear, month: this.selectedMonth });
    },
    getComponentName(panelName) {
      const components = {
        'eventsCountByYearMonth': eventsCountByYearMonth,
        'eventsCountByLocation': eventsCountByLocation,
      };
      return components[panelName] || null;
    }
  },
};
</script>

<style lang="sass" scoped>
h1 
  margin: 15px 
select
  margin: 10px 10px 30px 10px
</style>
