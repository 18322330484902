<template>  
  <div>
    <SearchUser v-if="isNotDashboardRoot"/>
    <HomeButton :isNotDashboardRoot="isNotDashboardRoot" />
    <button class="gear-button" @click="openModal" v-if="isNotDashboardRoot">
      
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. --> <path d="M495.9 166.6c3.2 8.7 .5 18.4-6.4 24.6l-43.3 39.4c1.1 8.3 1.7 16.8 1.7 25.4s-.6 17.1-1.7 25.4l43.3 39.4c6.9 6.2 9.6 15.9 6.4 24.6c-4.4 11.9-9.7 23.3-15.8 34.3l-4.7 8.1c-6.6 11-14 21.4-22.1 31.2c-5.9 7.2-15.7 9.6-24.5 6.8l-55.7-17.7c-13.4 10.3-28.2 18.9-44 25.4l-12.5 57.1c-2 9.1-9 16.3-18.2 17.8c-13.8 2.3-28 3.5-42.5 3.5s-28.7-1.2-42.5-3.5c-9.2-1.5-16.2-8.7-18.2-17.8l-12.5-57.1c-15.8-6.5-30.6-15.1-44-25.4L83.1 425.9c-8.8 2.8-18.6 .3-24.5-6.8c-8.1-9.8-15.5-20.2-22.1-31.2l-4.7-8.1c-6.1-11-11.4-22.4-15.8-34.3c-3.2-8.7-.5-18.4 6.4-24.6l43.3-39.4C64.6 273.1 64 264.6 64 256s.6-17.1 1.7-25.4L22.4 191.2c-6.9-6.2-9.6-15.9-6.4-24.6c4.4-11.9 9.7-23.3 15.8-34.3l4.7-8.1c6.6-11 14-21.4 22.1-31.2c5.9-7.2 15.7-9.6 24.5-6.8l55.7 17.7c13.4-10.3 28.2-18.9 44-25.4l12.5-57.1c2-9.1 9-16.3 18.2-17.8C227.3 1.2 241.5 0 256 0s28.7 1.2 42.5 3.5c9.2 1.5 16.2 8.7 18.2 17.8l12.5 57.1c15.8 6.5 30.6 15.1 44 25.4l55.7-17.7c8.8-2.8 18.6-.3 24.5 6.8c8.1 9.8 15.5 20.2 22.1 31.2l4.7 8.1c6.1 11 11.4 22.4 15.8 34.3zM256 336a80 80 0 1 0 0-160 80 80 0 1 0 0 160z"/></svg>        
    </button>
    <Modal :isOpen="isModalOpen" @update:isOpen="isModalOpen = $event">
      <PanelConfigurator />
    </Modal>
    <TabNavigation v-if="shouldShowTabs" />
    <router-view :isModalOpen="isModalOpen" @closeModal="closeModal" v-if="shouldShowTabs" />
    <div v-if="isDashboardRoot">
      <!-- Contenido específico para la ruta /dashboard -->
      <AccessPanel @openModal="openModal"></AccessPanel>
    </div>
  </div>
</template>

<script>
import TabNavigation from '../components/TabNavigation.vue';
import Modal from '@/components/Modal.vue';
import PanelConfigurator from '@/components/PanelConfigurator.vue';
import SearchUser from '@/components/search/SearchUser.vue';
import AccessPanel from '@/components/AccessPanel.vue';
import HomeButton from '@/components/botones/HomeButton.vue';

export default {
  name: 'UserDashboard',
  components: {
    TabNavigation,
    Modal,
    PanelConfigurator,
    SearchUser,
    AccessPanel,
    HomeButton
  },
  computed: {
    shouldShowTabs() {
      // No mostrar tabs para detalles de usuario o en la ruta base de dashboard
      return this.$route.path !== '/dashboard' && this.$route.name !== 'UserDetails';
    },
    isDashboardRoot() {
      // Determinar si estamos en la ruta raíz de dashboard
      return this.$route.path === '/dashboard';
    },
    isNotDashboardRoot() {
      // Determinar si estamos en la ruta raíz de dashboard
      return this.$route.path !== '/dashboard';
    }
  },

  data() {
    return {
      isModalOpen: false,   
    };
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    
  },
};
</script>

<style scoped lang="sass">
.gear-button 
  position: absolute
  top: 10px
  right: 10px
  background: transparent
  border: none
  cursor: pointer
  color: #3C1053
  min-width: 10px
  min-height: 10px
svg 
  min-width: 30px
.home-button 
  position: absolute
  top: 10px
  right: 50px
  background: transparent
  border: none
  cursor: pointer
  color: #3C1053
  min-width: 35px
  min-height: 35px



svg path 
  fill: #3C1053


select
  width: 95%
  max-width: 400px
  padding: 10px 15px
  // border-radius: 30px
  margin: 15px 10px
  border: none
  box-shadow: 5px 5px 5px rgba(0,0,0,0.3)
  transition: all 0.2s
  &:hover
    box-shadow: 5px 5px 10px rgba(0,0,0,0.6)
    cursor: pointer
input
  width: 100%
  max-width: 400px
  padding: 10px 15px
  // border-radius: 30px
  margin: 15px 0 5px 0
  border: none
  box-shadow: 5px 5px 5px rgba(0,0,0,0.3)
  transition: all 0.2s
  &:hover
    box-shadow: 5px 5px 10px rgba(0,0,0,0.6)    



</style>
