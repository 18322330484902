<template>
    <div>
        <a class="home-button" href="/dashboard" v-if="isNotDashboardRoot"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512">
       <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. -->
       <path d="M280.4 148.3L96 300.1V464a16 16 0 0 0 16 16l112.1-.3a16 16 0 0 0 15.9-16V368a16 16 0 0 1 16-16h64a16 16 0 0 1 16 16v95.6a16 16 0 0 0 16 16.1L464 480a16 16 0 0 0 16-16V300L295.7 148.3a12.2 12.2 0 0 0 -15.3 0zM571.6 251.5L488 182.6V44.1a12 12 0 0 0 -12-12h-56a12 12 0 0 0 -12 12v72.6L318.5 43a48 48 0 0 0 -61 0L4.3 251.5a12 12 0 0 0 -1.6 16.9l25.5 31A12 12 0 0 0 45.2 301l235.2-193.7a12.2 12.2 0 0 1 15.3 0L530.9 301a12 12 0 0 0 16.9-1.6l25.5-31a12 12 0 0 0 -1.7-16.9z"/></svg></a>
    </div>
</template>
<script>
export default {
    props: ['isNotDashboardRoot'],
    name: 'HomeButton',
}
</script>
<style lang="sass" scoped>
.home-button 
  position: absolute
  top: 10px
  right: 50px
  background: transparent
  border: none
  cursor: pointer
  color: #3C1053
  min-width: 35px
  min-height: 35px



svg path 
  fill: #3C1053

</style>