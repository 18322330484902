<template>
  <div v-if="isVisible">
    <h2>Distribución de Usuarios por Ubicación</h2>
    <div>
      <select v-model="selectedCountry">
        <option value="">Todos los países</option>
        <option v-for="country in allCountries" :key="country" :value="country">{{ country }}</option>
      </select>
      <select v-model="selectedCity">
        <option value="">Todas las ciudades</option>
        <option v-for="city in filteredCities" :key="city" :value="city">{{ city }}</option>
      </select>
    </div>
    <BarChart :chartData="chartData" :options="options" />
  </div>
</template>
<script>
import { BarChart } from 'vue-chart-3';
import { getUsersDistributionByLocation } from './UsersDistributionByLocationService';
import { mapState } from 'vuex';

export default {
  name: 'UsersDistributionByLocation',
  components: {
    BarChart
  },
  props: {
    year: String,
    month: String
  },
  data() {
    return {
      chartData: null,
      selectedCountry: '',
      selectedCity: '',
      allCountries: [], // Almacena todos los países disponibles
      allCities: [],    // Almacena todas las ciudades disponibles
      filteredCities: [], // Ciudades filtradas basadas en el país seleccionado
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            title: {
              display: true,
              text: ''
            }
          },
          y: {
            title: {
              display: true,
              text: 'Total Users'
            },
            beginAtZero: true
          }
        }
      },
    };
  },
  computed: {
    ...mapState({
      isVisible: state => state.paneles.user.find(p => p.name === 'UsersDistributionByLocation').visible
    }),
  },
  methods: {
    async fetchData() {
      try {
        const data = await getUsersDistributionByLocation(this.year, this.month, this.selectedCountry, this.selectedCity);
        this.chartData = {
          labels: data.map(d => `${d.pais}, ${d.ciudad}`),
          datasets: [{
            label: 'Total de usuarios',
            backgroundColor: '#3C1053',
            data: data.map(d => d.total_usuarios),
          }],
        };
        this.updateLocations(data);
      } catch (error) {
        console.error('Failed to fetch data', error);
      }
    },
    updateLocations(data) {
      if (!this.allCountries.length) {
        this.allCountries = [...new Set(data.map(d => d.pais))];
      }
      if (!this.selectedCity) { // Update cities only if no city is selected
        this.filteredCities = this.selectedCountry
          ? [...new Set(data.filter(d => d.pais === this.selectedCountry).map(d => d.ciudad))]
          : [...new Set(data.map(d => d.ciudad))];
      }
    },
  },
  watch: {
    year: 'fetchData',
    month: 'fetchData',
    selectedCountry(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.selectedCity = ''; // Reset the city when the country changes
        this.fetchData();
      }
    },
    selectedCity(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchData(); // Trigger fetchData only if the city selection changes
      }
    },
    isVisible(newVal) {
      if (newVal) {
        this.fetchData();
      }
    }
  },
  mounted() {
    if (this.isVisible) {
      this.fetchData();
    }
  },
};
</script>

<style scoped>
/* Estilos adicionales si es necesario */
</style>
