<template>
  <div class="user-metrics">
    <!-- Botón y div para detalles del usuario eliminados -->
    <GeneralUser></GeneralUser>
  </div>
</template>

<script>
import GeneralUser from './UserMetrics/GeneralUser.vue';

export default {
  components: {
    GeneralUser,
  },
  data() {
    return {      
    }
  },

};
</script>

<style scoped>


button {
  
  margin-top: 20px;
  background: #3C1053;
  color: #fff;
  display: block;
  /* margin: 5px 10px; */
  border: none;
  border-radius: 30px;
  padding: 15px;
  text-align: center;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.3s, color 0.3s;
}
</style>
