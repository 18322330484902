<template>
  <div>
    <div v-for="(panelList, type) in validPanels" :key="type">
      <h2>Configuración del panel {{ type }}</h2>
      <div class="block-check"  >

        <div v-for="panel in panelList" :key="panel.name" class="checkbox-container">
          <input type="checkbox" :id="panel.name" :checked="panel.visible" @change="togglePanel({ panelType: type, panelName: panel.name })" hidden>
          <label :for="panel.name" :class="{'active': panel.visible, 'inactive': !panel.visible}">
            {{ panel.name }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';

export default {
  computed: {
    ...mapState(['paneles']),
    validPanels() {
      return Object.keys(this.paneles).reduce((acc, key) => {
        if (Array.isArray(this.paneles[key])) {
          acc[key] = this.paneles[key];
        }
        return acc;
      }, {});
    }
  },
  methods: {
    ...mapActions(['togglePanel']),
  },
};
</script>

<style scoped>
.checkbox-container {
  margin-bottom: 10px;
}

input[type="checkbox"] {
  display: none; /* Oculta el checkbox pero sigue funcional */
}

label {
  display: inline-block;
  padding: 10px 15px;
  border: 1px solid transparent;
  border-radius: 10px;
  cursor: pointer;
  user-select: none;
  transition: background-color 0.3s, color 0.3s, border-color 0.3s;
}
.block-check {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 15px auto;
}

label.active {
  background-color: #3C1053;
  color: white;
  border: 2px solid #3C1053;
}

label.inactive {
  background-color: #eeeeee;
  color: #333;
  border: 2px solid #ccc;
}
</style>
