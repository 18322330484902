<template>
    <div>
      <button @click="openModal">Crear grupo</button>
      <div v-if="isModalOpen" class="modal" @click="checkOutsideClick">
        <div class="modal-content" @click.stop>
          <span @click="closeModal" class="close">&times;</span>
          <form @submit.prevent="createGroup">
            <div>
              <label for="groupName">Nombre del grupo:</label>
              <input id="groupName" v-model="groupName" type="text" required>
            </div>
            <div>
              <label for="groupDescription">Descripción:</label>
              <textarea id="groupDescription" v-model="groupDescription"></textarea>
            </div>
            <button type="submit">Guardar</button>
          </form>
        </div>
      </div>
    </div>
  </template>

<script>

import axios from 'axios';

export default {
  data() {
    return {
      isModalOpen: false,
      groupName: '',
      groupDescription: '',
    };
  },
  methods: {
    openModal() {
      this.isModalOpen = true;
    },
    closeModal() {
      this.isModalOpen = false;
    },
    checkOutsideClick(event) {
      if (event.target.classList.contains('modal')) {
        this.closeModal();
      }
    },
    createGroup() {
      axios.post('/api/groups/create', {
          name: this.groupName,
          description: this.groupDescription
      }).then(response => {
          this.$emit('groupCreated', response.data);
          this.closeModal();
      }).catch(error => {
          console.error('Error creating group:', error);
      });
    }
  }
}
</script>

  
<style lang="sass" scoped>
  
.modal
    display: block
    position: fixed
    z-index: 1
    left: 0
    top: 0
    width: 100%
    height: 100%
    overflow: auto
    background-color: rgba(0,0,0,0.4)

.modal-content
    background-color: #fefefe
    margin: 15% auto
    padding: 20px
    border: 1px solid #888
    width: 80%
    max-width: 600px
    padding-top: 30px
    position: relative
    input, textarea
        display: block
        width: 100%
        padding: 15px 10px 
        border: none
        border-bottom: 2px solid #000
        margin-bottom: 20px

.close
    color: #aaa
    position: absolute
    top: 10px 
    right: 10px
    font-size: 28px
    font-weight: bold
    &:hover, &:focus
        color: black
        text-decoration: none
        cursor: pointer


button
    margin-top: 15px
    display: inline-block
    margin: 5px 10px
    border: none
    border-radius: 5px
    padding: 10px 15px
    text-align: center            
    cursor: pointer
    font-size: 16px
    transition: background-color 0.3s, color 0.3s
    width: 100% 
    max-width: 150px
    
    background: #000 // color principal
    color: #fff
    &:hover
        background: #8246AF // color lila para hover
        color: #fff

  </style>
  