<template>
    <div class="contenedor-form-general" v-if="roleLoaded && canEdit">
      <HomeButton :isNotDashboardRoot="isNotDashboardRoot" />
      <div class="contenedor-form">
        <p class="font-bold titulo">Editar Mi Perfil</p>
        <form @submit.prevent="handleUpdate">
          <div class="correo">
            <span>Nombre</span>
            <input v-model="nombre" type="text" placeholder="Nombre y apellido">
          </div>
          <div class="correo">
            <span>Dirección de email</span>
            <input v-model="email" type="email" placeholder="ejemplo@mail.com">
          </div>
          <div class="correo">
            <span>Contraseña</span>
            <input v-model="password" type="password" placeholder="Debe tener al menos 7 caracteres">
          </div>
          <div class="correo">
            <span>Confirmar contraseña</span>
            <input v-model="confirmPassword" type="password">
          </div>
          <div class="correo">
            <span>Rol</span>
            <select v-model="role" required>
              <option value="">-- Selecciona un rol --</option>
              <option v-for="option in availableRoles" :value="option.value" :key="option.value">{{ option.text }}</option>
            </select>
          </div>
          <button type="submit">Actualizar Información</button>
        </form>
      </div>
      
    </div>
    <div v-else>
      <p>Cargando o acceso denegado...</p>
    </div>
</template>

<script>
import axios from 'axios';
import HomeButton from '@/components/botones/HomeButton.vue';

export default {
  props: ['token'],
  components: {
      HomeButton
  },
  data() {
    return {
      nombre: '',
      email: '',
      role: '',
      password: '',
      confirmPassword: '',
      availableRoles: [],
      isNotDashboardRoot: true,
      loading: true, // Controla el estado de carga global
      roleLoaded: false,
      canEdit: false,
      currentUserToken: '', // Token del usuario actual
    };
  },
  async created() {
    this.currentUserToken = this.$store.state.user.token;
    await this.initializeComponent();
  },
  methods: {
    async initializeComponent() {
      await this.fetchUserData();
      await this.setAvailableRoles();
      this.loading = false; // Asegura que toda la carga ha terminado
      if (!this.canEdit) {
        this.$router.push('/403'); // Solo redirige una vez que todo está listo
      }
    },
    async fetchUserData() {
      try {
        const response = await axios.get(`/api/user/${this.token}`);
        const { nombre, email, role } = response.data;
        this.nombre = nombre;
        this.email = email;
        this.role = role;
      } catch (error) {
        console.error('Error fetching user data:', error);
        this.loading = false; // Actualiza el estado de carga en caso de error
      }
    },
    async setAvailableRoles() {
      try {
        const response = await axios.post('/api/verify-role', {
          roleToken: this.$store.state.roleToken
        });
        this.roleAuth = response.data.role;
        this.canEdit = this.checkEditPermission(this.roleAuth, this.role, this.token === this.currentUserToken);
        this.availableRoles = this.getRolesBasedOnAuth(this.roleAuth);
        this.roleLoaded = true;
      } catch (error) {
        console.error('Error verifying role:', error);
        this.roleLoaded = true; // Asegura que el estado se actualiza incluso en caso de error
      }
    },
    checkEditPermission(roleAuth, userRole, isSelf) {
      const roleHierarchy = {
        'usuario': 1,
        'administrador': 2,
        'superadministrador': 3
      };
      if (roleAuth === 'usuario' && !isSelf) {
        return false; // Usuarios solo pueden editar su propio perfil
      }
      return roleHierarchy[roleAuth] >= roleHierarchy[userRole];
    },
    getRolesBasedOnAuth(roleAuth) {
      switch (roleAuth) {
        case 'superadministrador':
          return [{ value: 'superadministrador', text: 'Superadministrador' },
                  { value: 'administrador', text: 'Administrador' },
                  { value: 'usuario', text: 'Usuario' }];
        case 'administrador':
          return [{ value: 'administrador', text: 'Administrador' },
                  { value: 'usuario', text: 'Usuario' }];
        default:
          return [{ value: 'usuario', text: 'Usuario' }];
      }
    },
    
handleUpdate() {
    if (!this.validateEmail(this.email)) {
        alert("Por favor, introduce un correo electrónico válido.");
        return;
    }
    if (this.password !== this.confirmPassword) {
        alert("Las contraseñas no coinciden.");
        return;
    }
  axios.post(`/api/user/update/${this.token}`, {
    nombre: this.nombre,
    email: this.email,
    role: this.role,
    token: this.token,
    password: this.password
  }).then(response => {
    if (response.data.success) {
      alert('Información actualizada correctamente');
    } else {
      alert(response.data.error);
    }
  }).catch(error => {
    console.error('Error updating user:', error);
    alert('Hubo un problema con tu solicitud de actualización.');
  });
},
    validateEmail(email) {
      const re = /^([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    }
  }
}
</script>


  <style lang="sass" scoped >
  .contenedor-form-general
      width: 100%
      display: flex
      align-items: center
      flex-direction: column
      justify-content: center
      height: 100%
      .contenedor-form, .caja-blanca
          padding: 20px 15px 15px 15px
          background: #fff
          // border-radius: 20px
          width: 100%
          max-width: 529px
          margin-top: 20px
          .titulo
              font-size: 32px
              text-align: center
              margin-bottom: 20px
          form 
              position: relative
              text-align: left
              .correo, .password
                  span
                      display: block
                      width: 100%
                      margin: 0
                      padding: 0
                      font-size: 20px
                      font-weight: bold
                      margin: 25px 0 0 0
                  input
                      width: 100%
                      border: none
                      padding: 10px 15px
                      // border-radius: 5px
                      background: #fff
                      border-bottom: 2px solid #000
                      transition: all 0.2s
                      margin-bottom: 20px
                      &:focus, &:active                                                
                        outline: none
                        border: none!important
                        border-bottom: 2px solid #999!important
              button
                  background: #000
                  color: #fff
                  margin-top: 15px
                  font-size: 0.8em
                  text-align: center
              .password
                  margin-top: 15px
          button
              display: block
              width: 100%
              border: none            
              padding: 15px
              text-align: left            
              cursor: pointer
              font-size: 16px
          .terminos, .pass-olvidado
              font-size: 0.7em
              max-width: 100%
              margin: 20px auto
              a
                  color: #555
                  text-decoration: none   
                  cursor: pointer
                  transition: all 0.2s
                  font-weight: bold
                  text-decoration: underline
                  &:hover
                    color: #000
          .pass-olvidado
              display: flex
              align-items: center
              svg
                  transform: scale(0.9)
                  margin-left: 5px        
      .caja-blanca
          button
              background: #000
              color: #fff
              text-align: center
  /* Animaciones */
  // .slide-fade-enter-active, .slide-fade-leave-active
  //     transition: all 0.2s ease
  
  // .slide-fade-enter, .slide-fade-leave-to /* Inicio y fin de estado para enter/leave */
  //     transform: translateY(10px)
  //     opacity: 0
  
  .fade-enter-active, .fade-leave-active
      transition: opacity 0.2s ease
  .fade-enter, .fade-leave-to
      opacity: 0
  .move-up-enter-active, .move-up-leave-active
      transition: transform 0.2s ease, opacity 0.2s ease
  .move-up-enter, .move-up-leave-to
      transform: translateY(-20px)
      opacity: 0
  .slide-fade-enter-active, .slide-fade-leave-active
      transition: all 0.2s ease
  .slide-fade-enter, .slide-fade-leave-to
      transform: translateY(10px)
      opacity: 0
  .email-placeholder
      cursor: pointer
      position: absolute
      line-height: 0
      opacity: 0
      // top: 0
      // display: none
      height: 0
      transition: all 0.2s
  .email-placeholder.active
      // display: block
      position: relative
      height: auto
      line-height: 1
      opacity: 1
  .password
      opacity: 0
  .password.pass-active
      opacity: 1
  .logo 
    width: fit-content
    margin: 20px
    padding: 10px
    font-size: 24px 
    font-family: Arial, Helvetica, sans-serif
    font-weight: bold
    text-align: right
    line-height: 0.6
select 
    width: 100%
    max-width: 100%
    margin: 10px auto 0 auto
    border: 2px solid #000
  </style>