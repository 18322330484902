
<!-- SelectRegion.vue -->
<template>
    <div>
      <button @click="openModal" class="region-button">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 496 512">
            <!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. --><path d="M248 8C111 8 0 119 0 256s111 248 248 248 248-111 248-248S385 8 248 8zm82.3 357.6c-3.9 3.9-8 8-11.3 11.3-3 3-5.1 6.7-6.2 10.7-1.5 5.7-2.7 11.4-4.8 16.9l-17.4 46.9c-13.8 3-28 4.7-42.7 4.7v-27.4c1.7-12.6-7.6-36.3-22.6-51.3-6-6-9.4-14.1-9.4-22.6v-32c0-11.6-6.3-22.3-16.5-28-14.4-8-34.8-19.1-48.8-26.1-11.5-5.8-22.1-13.1-31.7-21.8l-.8-.7a114.8 114.8 0 0 1 -18.1-20.7c-9.4-13.8-24.7-36.4-34.6-51.1 20.5-45.5 57.4-82 103.2-101.9l24 12C203.5 89.7 216 82 216 70.1v-11.3c8-1.3 16.1-2.1 24.4-2.4l28.3 28.3c6.3 6.3 6.3 16.4 0 22.6L264 112l-10.3 10.3c-3.1 3.1-3.1 8.2 0 11.3l4.7 4.7c3.1 3.1 3.1 8.2 0 11.3l-8 8a8 8 0 0 1 -5.7 2.3h-9c-2.1 0-4.1 .8-5.6 2.3l-9.9 9.7a8 8 0 0 0 -1.6 9.3l15.6 31.2c2.7 5.3-1.2 11.6-7.2 11.6h-5.6c-1.9 0-3.8-.7-5.2-2l-9.3-8.1a16 16 0 0 0 -15.6-3.1l-31.2 10.4a12 12 0 0 0 -8.2 11.3c0 4.5 2.6 8.7 6.6 10.7l11.1 5.5c9.4 4.7 19.8 7.2 30.3 7.2s22.6 27.3 32 32h66.8c8.5 0 16.6 3.4 22.6 9.4l13.7 13.7a30.5 30.5 0 0 1 8.9 21.6 46.5 46.5 0 0 1 -13.7 33zM417 274.3c-5.8-1.5-10.8-5-14.2-10l-18-27a24 24 0 0 1 0-26.6l19.6-29.4c2.3-3.5 5.5-6.3 9.2-8.2l13-6.5C440.2 193.6 448 223.9 448 256c0 8.7-.7 17.2-1.8 25.5L417 274.3z"/></svg>
      </button>
      <div v-if="showModal" class="modal-overlay" @click.self="closeModal">
        <div class="modal-content">
          <span @click="closeModal" class="close">&times;</span>
          <h3>Asignar Países al Grupo</h3>
          <div class="block-check">
            <div v-for="region in regions" :key="region.id_region" class="checkbox-container">
              <input type="checkbox" :id="`region-${region.id_region}`" :value="region.id_region" v-model="activeRegions" hidden>
              <label :for="`region-${region.id_region}`" :class="{'active': isRegionActive(region), 'inactive': !isRegionActive(region)}">
                {{ region.name }}
              </label>
            </div>
          </div>
          <button @click="updateRegions" class="update-button">Actualizar</button>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    props: ['groupId'],
    data() {
      return {
        showModal: false,
        regions: [],
        activeRegions: []
      };
    },
    methods: {
      openModal() {
        this.fetchRegions();
        this.showModal = true;
      },
      closeModal() {
        this.showModal = false;
      },
      fetchRegions() {
        axios.get('/api/regions').then(response => {
          this.regions = response.data;
          this.fetchActiveRegions();
        });
      },
      fetchActiveRegions() {
        axios.get(`/api/group_regions/${this.groupId}`).then(response => {
          this.activeRegions = response.data.map(region => region.id_region);
        });
      },
      isRegionActive(region) {
        return this.activeRegions.includes(region.id_region);
      },
      updateRegions() {
        axios.post(`/api/group_regions/update/${this.groupId}`, { regions: this.activeRegions })
          .then(() => {
            this.closeModal();
            alert('Países actualizados correctamente.');
          })
          .catch(error => {
            console.error('Error updating regions:', error);
            alert('Hubo un problema al actualizar los países.');
          });
      }
    }
  }
  </script>
  
  
  <style lang="sass" scoped>
.modal-overlay 
    position: fixed
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: rgba(0,0,0,0.5)
    display: flex
    justify-content: center
    align-items: center
    z-index: 2
  

.modal-content 
    background: white
    padding: 20px
    border-radius: 5px
    width: 300px
    position: relative
  
.close 
    cursor: pointer
    position: absolute
    top: 10px
    right: 10px
    font-size: 24px
  
  
.is-active 
    font-weight: bold

.region-button 
    position: absolute
    left: 0
    right: auto
    bottom: 0
    top: 0
    margin: auto
    border-radius: 5px 0 0 0
    background-color: #1b1b1b        
    border: none
    padding: 8px 12px
    // border-radius: 4px
    cursor: pointer
    transition: background 0.3s ease
    text-decoration: none
    font-size: 0.8em
    // margin-left: 10px
    &:hover
        background-color: #8246AF

.region-button svg 
    height: 20px
    fill: #fff

.checkbox-container
    margin: 10px auto
label 
    display: block
    padding: 10px 15px
    border: 1px solid transparent
    border-radius: 10px
    cursor: pointer
    user-select: none
    transition: background-color 0.3s, color 0.3s, border-color 0.3s
    width: 80%
    margin: 0 auto

label.active 
    background-color: #3C1053
    color: white
    border: 2px solid #3C1053


label.inactive 
    background-color: #eeeeee
    color: #333
    border: 2px solid #ccc

.update-button
    margin-top: 15px
    display: inline-block
    margin: 15px auto 5px auto
    border: none
    border-radius: 5px
    padding: 10px 15px
    text-align: center            
    cursor: pointer
    font-size: 16px
    transition: background-color 0.3s, color 0.3s
    width: 100% 
    // max-width: 150px
    
    background: #000 // color principal
    color: #fff
    &:hover
        background: #8246AF // color lila para hover
        color: #fff
h3 
    margin-bottom: 20px
</style>