<template>
    <div class="forbidden-container">
      <h1>403 - Acceso Prohibido</h1>
      <p>Lo sentimos, no tienes permiso para acceder a esta página.</p>
      <div class="botones">

        <button @click="goBack">Volver</button> <button @click="goHome">Ir al inicio</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Forbidden',
    methods: {
      goBack() {
        this.$router.go(-1); // O redirige a una ruta específica si prefieres
      },
      goHome() {
        this.$router.push('/dashboard'); // O redirige a una ruta específica si prefieres
      }
    }
  }
  </script>
  
  <style scoped>
  .forbidden-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
  }
  
  h1 {
    font-size: 2em;
    color: #E53935; /* Rojo */
  }
  
  p {
    font-size: 1.5em;
    margin: 20px;
  }
  
  button {
    padding: 10px 20px;
    font-size: 1em;
    background-color: #1976D2; /* Azul */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    margin: 0 10px;
    transition: background-color 0.3s;
  }
  
  button:hover {
    background-color: #1565C0;
  }
  .botones {
    display: flex;
    justify-content: center;
  }
  </style>
  