<template>
    <div>
      <button class="edit-button" @click="openModal"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. --><path d="M290.7 93.2l128 128-278 278-114.1 12.6C11.4 513.5-1.6 500.6 .1 485.3l12.7-114.2 277.9-277.9zm207.2-19.1l-60.1-60.1c-18.8-18.8-49.2-18.8-67.9 0l-56.6 56.6 128 128 56.6-56.6c18.8-18.8 18.8-49.2 0-67.9z"/></svg></button>
      <div v-if="isModalOpen" class="modal" @click="closeModal">
        <div class="modal-content" @click.stop>
          <span @click="closeModal" class="close">&times;</span>
          <h2>Editar Grupo</h2>
          <form @submit.prevent="updateGroup">
            <div>
              <label for="groupName">Nombre del grupo:</label>
              <input id="groupName" v-model="groupName" type="text" required>
            </div>
            <div>
              <button type="submit" class="btn-update">Actualizar</button>
              <button type="button" @click="confirmDelete" :class="{'btn-delete': canDelete, 'btn-disabled': !canDelete}">Eliminar Grupo</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  export default {
    props: {
      group: Object
    },
    data() {
      return {
        isModalOpen: false,
        groupName: '',
        canDelete: false
      };
    },
    methods: {
      openModal() {
        this.isModalOpen = true;
        this.groupName = this.group.group_name;
        this.canDelete = this.group.users.length === 0;
      },
      closeModal() {
        this.isModalOpen = false;
      },
      updateGroup() {
        axios.post(`/api/groups/update`, {
          name: this.groupName,
          tokenGroup: this.group.group_id
        }).then(response => {
          if (response.data.success) {
            this.$emit('groupUpdated', { group_id: this.group.group_id, group_name: this.groupName });
            this.closeModal();
          }
        }).catch(error => {
          console.error('Error updating group:', error);
        });
      },
      confirmDelete() {
        if (!this.canDelete) return;
        if (confirm('¿Estás seguro de que quieres eliminar este grupo?')) {
          axios.post(`/api/groups/delete/${this.group.group_id}`).then(response => {
            if (response.data.success) {
              this.$emit('groupDeleted', this.group.group_id);
              this.closeModal();
            }
          }).catch(error => {
            console.error('Error deleting group:', error);
          });
        }
      }
    }
  }
  </script>
  
  
<style lang="sass" scoped>
  
.modal
    display: block
    position: fixed
    z-index: 1
    left: 0
    top: 0
    width: 100%
    height: 100%
    overflow: auto
    background-color: rgba(0,0,0,0.4)

.modal-content
    background-color: #fefefe
    margin: 15% auto
    padding: 20px
    border: 1px solid #888
    width: 80%
    max-width: 600px
    padding-top: 30px
    position: relative
    input, textarea
        display: block
        width: 100%
        padding: 15px 10px 
        border: none
        border-bottom: 2px solid #000
        margin-bottom: 20px

.close
    color: #aaa
    position: absolute
    top: 10px 
    right: 10px
    font-size: 28px
    font-weight: bold
    &:hover, &:focus
        color: black
        text-decoration: none
        cursor: pointer


button
    margin-top: 15px
    display: inline-block
    margin: 5px 10px
    border: none
    border-radius: 5px
    padding: 10px 15px
    text-align: center            
    cursor: pointer
    font-size: 16px
    transition: background-color 0.3s, color 0.3s
    width: 100% 
    max-width: 150px
    
    background: #000 // color principal
    color: #fff
    &:hover
        background: #8246AF // color lila para hover
        color: #fff

.btn-update 
    background-color: green
.btn-delete 
    background-color: red
.btn-disabled 
    background-color: grey
    cursor: not-allowed
.edit-button 
    padding: 5px 5px 1px 5px
    box-shadow: 2px 2px 10px rgba(155,155,155, 0.6)
    background: #fff!important
    margin: 0 10px
    transition: all 0.2s
    svg 
        transition: all 0.2s
    &:hover
        background: #8246AF!important
        svg 
            fill: #fff
    
        
</style>
  