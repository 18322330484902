<template>
    <div>
      <div>
        <button @click="updateAllPanels">Update All Panels</button>
      </div>
      <button 
      :class="{ active: currentTab === 'GeneralMetrics', inactive: currentTab !== 'GeneralMetrics' }"
      @click="selectTab('GeneralMetrics', '/dashboard/general-metrics')">Métricas Generales</button>
    <button 
      :class="{ active: currentTab === 'EventMetrics', inactive: currentTab !== 'EventMetrics' }"
      @click="selectTab('EventMetrics', '/dashboard/event-metrics')">Métricas Eventos</button>
    <button 
      :class="{ active: currentTab === 'UserMetrics', inactive: currentTab !== 'UserMetrics' }"
      @click="selectTab('UserMetrics', '/dashboard/user-metrics')">Métricas Usuarios</button>

      
      <keep-alive>
        <component :is="currentTab"></component>
      </keep-alive>

    </div>
  </template>
  
  <script>
  import GeneralMetrics from './metrics/GeneralMetrics.vue';
  import EventMetrics from './metrics/EventMetrics.vue';
  import UserMetrics from './metrics/UserMetrics.vue';
  
  export default {
    data() {
    return {
      currentTab: null,
    };
  },
    mounted() {
    this.initializeCurrentTab();
  },

  watch: {
  '$route'(newRoute) {
    switch(newRoute.path) {
      case '/dashboard/general-metrics':
        this.currentTab = 'GeneralMetrics';
        break;
      case '/dashboard/event-metrics':
        this.currentTab = 'EventMetrics';
        break;
      case '/dashboard/user-metrics':
        this.currentTab = 'UserMetrics';
        break;
      default:
        this.currentTab = null;
    }
  }
},

  created() {
    this.initializeCurrentTab();
  },
    components: {
      GeneralMetrics,
      EventMetrics,
      UserMetrics,
    },
    methods: {
      selectTab(tab, path) {
        if (this.$route.path !== path) {
          this.$router.push(path, () => {
            this.currentTab = tab; // Actualizar después de que la navegación es confirmada
          });
        }         
        this.currentTab = tab; // Aún actualiza si la ruta no cambia
      },

      updateAllPanels() {
      const newPanels = {
          general: [
            { name: 'eventsCountByYearMonth', visible: true },
            { name: 'eventsCountByLocation', visible: true },          
            // { name: 'eventsCountByYearMonth2', visible: true },
            // { name: 'eventsCountByYearMonth', visible: true },
            
          ],
          event: [            
            { name: 'eventsCountByYearMonthEvent', visible: true },
            ],
          user: [            
            { name: 'UsersRegistrationsByYearMonthWeek', visible: true },          
            { name: 'UsersDistributionByLocation', visible: true },          
          ],
        };
        this.$store.dispatch('updateAllPanels', newPanels);
      },
      initializeCurrentTab() {
        switch(this.$route.path) {
          case '/dashboard/general-metrics':
            this.currentTab = 'GeneralMetrics';
            break;
          case '/dashboard/event-metrics':
            this.currentTab = 'EventMetrics';
            break;
          case '/dashboard/user-metrics':
            this.currentTab = 'UserMetrics';
            break;
          default:
            this.currentTab = null; // O manejar según sea necesario
        }
      }

    },
  };
  </script>
  
<style lang="sass" scoped>
button
  margin-top: 15px
  display: inline-block
  margin: 5px 10px
  border: none
  // border-radius: 30px
  padding: 10px 15px
  text-align: center            
  cursor: pointer
  font-size: 16px
  transition: background-color 0.3s, color 0.3s
  width: 100% 
  max-width: 350px

.active
  background: #000 // color principal
  color: #fff

.inactive
  background: gray
  color: #ccc
  &:hover
    background: #8246AF // color lila para hover
    color: #fff

</style>
