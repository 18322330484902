<template>
    <div>
      <button class="document-button" @click="openModal">Agregar Región</button>
      <div v-if="showModal" class="modal modal-overlay" @click.self="closeModal">
        <div class="modal-content">
          <span @click="closeModal" class="close">&times;</span>
          <form @submit.prevent="submitRegion">
            <div class="form-group">
              <label>Nombre de la región</label>
              <input v-model="newRegion.name" placeholder="Nombre de la región" required>
            </div>
            <div class="form-group">
              <label>Países (separados por comas)</label>
              <input v-model="newRegion.countries" placeholder="Países" required>
            </div>
            <div class="form-actions">
              <button type="submit" class="button">{{ isEditMode ? 'Actualizar' : 'Guardar' }}</button>
              <button type="button" class="button button-cancel" @click="closeModal">Cancelar</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        newRegion: { id: null, name: '', countries: '' },
        showModal: false,
        isEditMode: false
      };
    },
    methods: {
        openModalWithRegion(region) {
            this.newRegion = {
                id: region.id_region, // Usa id_region que es la clave correcta según tus datos
                name: region.name,
                countries: region.countries.join(', ')
            };
            this.isEditMode = true;
            this.showModal = true;
        },

      openModal() {
        this.newRegion = { id: null, name: '', countries: '' };
        this.isEditMode = false;
        this.showModal = true;
      },
      submitRegion() {
        const regionData = {
            name: this.newRegion.name,
            countries: JSON.stringify(this.newRegion.countries.split(',').map(country => country.trim()))
        };
        const url = this.isEditMode ? `/api/regions/update/${this.newRegion.id}` : '/api/regions';
        axios.post(url, regionData).then(() => {
            this.$emit('refreshRegions');
            this.closeModal();
        }).catch(error => {
            console.error('Error submitting region:', error);
            alert('Hubo un problema al agregar o actualizar la región.');
        });
    },

      closeModal() {
        this.showModal = false;
        this.newRegion = { id: null, name: '', countries: '' };
      }
    }
  }
  </script>
  

<style lang="sass" scoped>
  
.modal
    display: block
    position: fixed
    z-index: 1
    left: 0
    top: 0
    width: 100%
    height: 100%
    overflow: auto
    background-color: rgba(0,0,0,0.4)

.modal-content
    background-color: #fefefe
    margin: 15% auto
    padding: 20px
    border: 1px solid #888
    width: 80%
    max-width: 600px
    padding-top: 30px
    position: relative
    input, textarea
        display: block
        width: 100%
        padding: 15px 10px 
        border: none
        border-bottom: 2px solid #000
        margin-bottom: 20px

.close
    color: #aaa
    position: absolute
    top: 10px 
    right: 10px
    font-size: 28px
    font-weight: bold
    &:hover, &:focus
        color: black
        text-decoration: none
        cursor: pointer


button, .form-actions button
    margin-top: 15px
    display: inline-block
    margin: 5px 10px
    border: none
    border-radius: 5px
    padding: 10px 15px
    text-align: center            
    cursor: pointer
    font-size: 16px
    transition: background-color 0.3s, color 0.3s
    width: 100% 
    max-width: 150px
    
    background: #000 // color principal
    color: #fff
    &:hover
        background: #8246AF // color lila para hover
        color: #fff

  </style>
  