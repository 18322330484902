<template>
    <div class="lista-de-usuarios">
      <HomeButton :isNotDashboardRoot="isNotDashboardRoot" />
      
      <div v-if="canView('superadministrador')" class="botones">
        <CreateGroupModal @groupCreated="handleGroupCreated" />
        
      </div>
      <h2>Lista de Usuarios por Grupo</h2>
      <div v-if="loading">
        Cargando usuarios...
      </div>
      <div v-else>
        <div class="contenedor-lista" v-for="group in groups" :key="group.group_id">
            <div class="header-group">
                <SelectRegion  v-if="canView('superadministrador')" :groupId="`${group.group_id}`"/>
                <h3>{{ group.group_name }} </h3>
                <EditGroupModal  v-if="canView('superadministrador')" :group="group" @groupUpdated="handleGroupUpdated" @groupDeleted="handleGroupDeleted"/>
                <button @click="addUser(group.group_id)" class="document-button"> <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.6.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc. --><path d="M416 208H272V64c0-17.7-14.3-32-32-32h-32c-17.7 0-32 14.3-32 32v144H32c-17.7 0-32 14.3-32 32v32c0 17.7 14.3 32 32 32h144v144c0 17.7 14.3 32 32 32h32c17.7 0 32-14.3 32-32V304h144c17.7 0 32-14.3 32-32v-32c0-17.7-14.3-32-32-32z"/></svg> 
                </button>
            </div>
          <table v-if="group.users.length > 0" class="styled-table">
            <thead>
              <tr>
                <th style="width: 35%;">Nombre</th>
                <th style="width: 35%;">Email</th>
                <th class="tab-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="user in group.users" :key="user.id">
                <td style="width: 35%;">{{ user.nombre }}</td>
                <td style="width: 35%;">{{ user.email }}</td>
                <td class="tab-rigth">
                  <button @click="editUser(user.id_token)" class="document-button">Editar</button>
                  <button @click="deleteUser(user.id_token)" class="document-button">Eliminar</button>
                </td>
              </tr>
            </tbody>
          </table>
          <table v-else class="styled-table">
            <thead>
              <tr>
                <th style="width: 35%;">Nombre</th>
                <th style="width: 29%;">Email</th>
                <th class="tab-center">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td ></td>
                <td style="text-align: center;">Sin usuarios</td>
                <td></td>
                
              </tr>
            </tbody>
          </table>
          
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  import HomeButton from '@/components/botones/HomeButton.vue';
  import CreateGroupModal from '@/components/administracion/modalgrupo/CreateGroupModal.vue';
  import EditGroupModal from '@/components/administracion/modalgrupo/EditGroupModal.vue';
  import SelectRegion from '@/components/administracion/modalgrupo/SelectRegion.vue';
  
  export default {
    name: 'ListaDeUsuarios',
    data() {
      return {
        groups: [],
        loading: true,
        isNotDashboardRoot: true,
        role: null,
        roleLoaded: false

      };
    },
    components: {
      HomeButton,
      CreateGroupModal,
      EditGroupModal,
      SelectRegion
    },
    watch: {
        // roleToken(newToken) {
        //     if (newToken) {
        //     this.validateRole();
        //     }
        // }
    },
    computed: {      
      roleToken() {
        return this.$store.state.roleToken;
      },
      
    },
    methods: {
      fetchGroups() {
        const token = this.$store.state.user.token; // Asegúrate de que el token se guarde y acceda correctamente
        axios.post('/api/usuarios', { Authorization: `${token}` } )
          .then(response => {
            this.groups = response.data;
            this.loading = false;
          })
          .catch(error => {
            console.error('Error fetching groups:', error);
            this.loading = false;
            if (error.response && error.response.status === 403) {
              this.$router.push({ name: 'Forbidden' });
            }
          });
        },
      addUser(groupId) {
          // Lógica para agregar un nuevo usuario al grupo específico
          this.$router.push('/dashboard/usuarios/agregar-usuario/'+groupId);
          console.log('Agregar usuario al grupo:', groupId);
      },
      editUser(userId) {
    // Construye la URL con el userId
          const url = `/dashboard/user/edit/${userId}`;
          // Abre la URL en una nueva pestaña
          window.open(url, '_blank');
      },

      deleteUser(userId) {
        // Lógica para eliminar un usuario
        console.log('Eliminar usuario:', userId);
      },
      handleGroupCreated(newGroup) {
          this.groups.push({
              group_id: newGroup.group_id,
              group_name: newGroup.group_name,
              users: [] // Asumiendo que inicialmente no hay usuarios en el grupo recién creado
          });
      },
      handleGroupUpdated(updatedGroup) {
        const index = this.groups.findIndex(group => group.group_id === updatedGroup.group_id);
        if (index !== -1) {
          this.groups[index].group_name = updatedGroup.group_name;
        }
      },
      handleGroupDeleted(groupId) {
        this.groups = this.groups.filter(group => group.group_id !== groupId);
      },
      async validateRole() {
        console.log("Role token:", this.roleToken);

        if (!this.roleToken) {
          this.roleLoaded = true;
          return;
        }
        try {
          const response = await axios.post('/api/verify-role', {
            roleToken: this.roleToken
          });
          console.log('Role response:', response.data); // Agregar un log para depuración
          this.role = response.data.role;
        } catch (error) {
          console.error('Error verifying role:', error);
          this.role = null;
        } finally {
          this.roleLoaded = true;
        }
      },
      canView(expectedRole) {
        if (!this.roleLoaded) {
            return false;
        }
        const roleHierarchy = {
            'usuario': 1,
            'administrador': 2,
            'superadministrador': 3
        };

        return roleHierarchy[this.role] >= roleHierarchy[expectedRole];
    },
    },
    mounted() {
      this.fetchGroups();
      this.validateRole(); // Llamar a la función directamente en lugar de usar async/await aquí
    }
  }
  </script>
  
  <style scoped>

  h2 {
    margin-bottom: 40px;
  }
  /* Estilos heredados del componente de referencia */
  .styled-table {
    width: 100%;
    border-bottom: 3px solid #000;
    border-collapse: collapse;
    margin: 0 0 25px 0;
    /* border-radius: 5px 5px 0 0; */
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0,0,0,0.15);
  }
  
  .styled-table thead tr {
    background-color: #1b1b1b;
    color: #ffffff;
    text-align: left;
    font-weight: bold;
  }
  
  .styled-table th, .styled-table td {
    padding: 12px 30px;
    text-align: left;
  }
  
  .styled-table tbody tr {
    border-bottom: 1px solid #dddddd;
  }
  
  .styled-table tbody tr:nth-of-type(even) {
    background-color: #f3f3f3;
  }
  
  .styled-table tbody tr:last-of-type {
    border-bottom: 2px solid #1b1b1b;
  }
  
  .styled-table tbody tr.active-row {
    font-weight: bold;
    color: #1b1b1b;
  }
  
  .document-button, .links a {
    background-color: #1b1b1b;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: background 0.3s ease;
    text-decoration: none;
    font-size: 0.8em;
    margin-right: 10px;
  }
  
  .document-button:hover {
    background-color: #8246AF;
  }

  .header-group{
    display: flex;
    justify-content: center;
    position: relative;
    padding: 10px;
    border: 3px solid #1b1b1b;
    border-radius: 10px 10px 0 0;
    align-items: center;

  }
  .header-group .document-button {
      position: absolute;
      left: auto;
      right: 0px;
      bottom: 0;
      top: 0;
      margin: auto;
      border-radius: 0 5px 0 0;
    }
    .header-group button svg {
        height: 20px;
        fill: #fff;
    }
  .contenedor-lista {
     max-width: 1200px;
     margin: 0 auto;
  }
  .tab-rigth {
    text-align: right!important;
  }
  .tab-center {
    text-align: right!important;
    padding-right: 50px!important;
  }
  </style>
  
