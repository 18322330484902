import axios from 'axios';

export const getUsersDistributionByLocation = async (year, month, country, city) => {
  
  const params = {};
  if (year) params.year = year;
  if (month) params.month = month;
  if (country) params.country = country;
  if (city) params.city = city;
  try {
    const response = await axios.post('/api/usuarios/usersDistributionByLocation', {params});
    return response.data;
  } catch (error) {
    console.error('Error fetching distribution data', error);
    throw error;
  }
};
