<template>
  <div v-if="isVisible">
    <h2>Eventos por ciudad</h2>
    <select v-model="selectedCity">
      <option value="">Todas las ciudades</option>
      <option v-for="city in allCities" :key="city" :value="city">{{ city }}</option>
    </select>
    <BarChart :chartData="chartData" :options="options" />
  </div>
</template>

<script>
import { BarChart } from 'vue-chart-3';
import { getEventsCountByLocation } from './eventsCountByLocationService';
import { mapState, mapActions } from 'vuex';

export default {
  name: 'eventsCountByLocation',
  components: {
    BarChart
  },
  props: {
    year: String,
    month: String
  },
  data() {
    return {
      chartData: null,
      selectedCity: '',
      allCities: [],  // Almacenará todas las ciudades disponibles
      cities: [],     // Usado para llenar el select
      options: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          x: {
            title: {
              display: true,
              text: 'Ciudades'
            }
          },
          y: {
            title: {
              display: true,
              text: 'Total de eventos'
            },
            beginAtZero: true
          }
        }
      },
    };
  },
  computed: {
    ...mapState({
      isVisible: state => state.paneles.general.find(p => p.name === 'eventsCountByLocation').visible
    }),
  },
  methods: {
    ...mapActions(['togglePanel']),
    async fetchData() {
      try {
        const data = await getEventsCountByLocation(this.year, this.month, this.selectedCity);
        if (Array.isArray(data)) {
          if (!this.selectedCity) {  // Si no se ha seleccionado ciudad, actualiza todas las ciudades
            this.allCities = [...new Set(data.map(item => item.ciudad))];
          }
          this.chartData = {
            labels: data.map(item => item.ciudad),
            datasets: [{
              label: 'Total de eventos por ciudad',
              backgroundColor: '#3C1053',
              data: data.map(item => item.total_eventos)
            }]
          };
        } else {
          console.error('Data is not an array:', data);
        }
      } catch (error) {
        console.error('Failed to fetch data', error);
      }
    }
  },
  watch: {
    year() {
      this.selectedCity = '';  // Limpia la selección de la ciudad
      this.fetchData();
    },
    month() {
      this.selectedCity = '';  // Limpia la selección de la ciudad
      this.fetchData();
    },
    selectedCity() {
      this.fetchData();
    },
    isVisible(newVal) {
      if (newVal) {
        this.fetchData();
      }
    }
  },
  mounted() {
    if (this.isVisible) {
      this.fetchData();
    }
  },
};
</script>

<style scoped>
/* Estilos adicionales si es necesario */
</style>
