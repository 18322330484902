<template>
  <div class="user-metrics">
    <!-- Botón y div para detalles del usuario eliminados -->
    <GeneralEvent></GeneralEvent>
  </div>
</template>

<script>
import GeneralEvent from './EventMetrics/GeneralEvent.vue';

export default {
  components: {
    GeneralEvent,
  },
  data() {
    return {      
    }
  },

};
</script>

<style lang="sass" scoped>

h1 
  margin: 15px 
select
  margin: 10px 10px 30px 10px

button 
  
  margin-top: 20px
  background: #3C1053
  color: #fff
  display: block
  /* margin: 5px 10px */
  border: none
  border-radius: 30px
  padding: 15px
  text-align: center
  cursor: pointer
  font-size: 16px
  transition: background-color 0.3s, color 0.3s

</style>
