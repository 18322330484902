<template>
    <div class="user-metrics-content">
      <h1>Métricas de Usuarios</h1>
      <!-- Selectores para Año y Mes -->
      <div class="contenedor-filtros">
        <select v-model="selectedYear">
          <option disabled value="">Seleccione un año</option>
          <option v-for="year in years" :key="year" :value="year">{{ year }}</option>
        </select>
        <select v-model="selectedMonth">
          <option value="">Todos los meses</option>
          <option v-for="month in 12" :key="month" :value="month">{{ month }}</option>
        </select>
      </div>
      <div v-for="panelName in visiblePanelNames" :key="panelName">
        <component :is="getComponentName(panelName)" :year="selectedYear" :month="selectedMonth" />
      </div>
    </div>
  </template>
  
  <script>
  import { mapState, mapActions } from 'vuex';
  import UsersRegistrationsByYearMonthWeek from './UsersRegistrationsByYearMonthWeek/UsersRegistrationsByYearMonthWeek.vue';
  import UsersDistributionByLocation from './UsersDistributionByLocation/UsersDistributionByLocation.vue';

//   import userEventsByLocation from './UserMetrics/userEventsByLocation.vue';
  
  export default {
    components: {
      UsersRegistrationsByYearMonthWeek,
      UsersDistributionByLocation,
    //   userEventsByLocation,
    },
    data() {
      return {
        selectedYear: '',
        selectedMonth: '',
        years: [2021, 2022, 2023, 2024]
      };
    },
    computed: {
      ...mapState(['paneles']),
      visiblePanelNames() {
        return this.paneles.user.filter(p => p.visible).map(p => p.name);
      },
    },
    methods: {
      ...mapActions(['togglePanel', 'applyFilters']),
      applyFilters() {
        this.$store.dispatch('applyFilters', { year: this.selectedYear, month: this.selectedMonth });
      },
      getComponentName(panelName) {
        const components = {
          'UsersRegistrationsByYearMonthWeek': UsersRegistrationsByYearMonthWeek,
          'UsersDistributionByLocation': UsersDistributionByLocation,        
        };
        return components[panelName] || null;
      }
    },
  };
  </script>
  

  <style lang="sass" scoped>

  h1 
    margin: 15px 
  select
    margin: 10px 10px 13px 10px
  
  button 
    
    margin-top: 20px
    background: #3C1053
    color: #fff
    display: block
    /* margin: 5px 10px */
    border: none
    border-radius: 30px
    padding: 15px
    text-align: center
    cursor: pointer
    font-size: 16px
    transition: background-color 0.3s, color 0.3s
  .contenedor-filtros
    margin-bottom: 15px
  </style>
  