import axios from 'axios';

export const getUsersRegistrationsByYearMonthWeek = async (year, month) => {
//   const params = { year, month }; // Simplificado para pasar ambos parámetros directamente
  const params = {};
  if (year) params.year = year;
  if (month) params.month = month;

  try {
    const response = await axios.post('/api/usuarios/usersRegistrationsByYearMonthWeek', {params});
    const rawData = response.data;

    // Determinar cómo formatear las etiquetas basadas en si el mes está definido
    let labels;
    let values = rawData.map(item => item.total_usuarios);
    if (month) {
      // Si el mes está definido, formatear como días
      labels = rawData.map(item => `Dia: ${item.día}`);
    } else {
      // Si no, formatear como mes y año
      labels = rawData.map(item => `Año: ${item.año}, Mes: ${item.mes}`);
    }

    return { labels, values };
  } catch (error) {
    console.error('Error fetching Users Metric', error);
    throw error;
  }
};
