<template>  
  <div>
    <div v-if="showPredictiveResults" class="overlay" @click="closeOverlay"></div>
    <div class="search-box">
      <input v-model="searchQuery" @input="fetchPredictiveResults" placeholder="Buscar usuario...">
      <ul v-if="showPredictiveResults && predictiveResults.length > 0">
        <li v-for="user in predictiveResults" :key="user.id_user" @click="selectUser(user)">
          {{ user.firstname }} {{ user.lastname }}
          <span> {{ user.matricula }} </span>
        </li>
      </ul>
      <div v-if="showPredictiveResults && predictiveResults.length === 0" class="no-results">
        No hay coincidencias
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SearchUser',    
  data() {
    return {        
      searchQuery: '',
      predictiveResults: [],
      showPredictiveResults: false,
      viewingUserDetails: false,
      selectedUser: null,
      searchTimeout: null, // Almacenar el identificador del temporizador
    };
  },
  methods: {
    fetchPredictiveResults() {
      clearTimeout(this.searchTimeout); // Limpia el temporizador anterior
      if (this.searchQuery.length > 0) {
        this.searchTimeout = setTimeout(() => {
          const url = `/api/search-user/${encodeURIComponent(this.searchQuery)}`;
          fetch(url)
            .then(response => response.json())
            .then(data => {
              this.predictiveResults = data.data; // Asegúrate de acceder a la propiedad data del objeto de respuesta
              this.showPredictiveResults = true;
            })
            .catch(error => console.error('Error fetching data:', error));
        }, 300); // Espera 300 milisegundos después del último evento de teclado
      } else {
        this.showPredictiveResults = false;
      }
    },
    selectUser(user) {
      this.selectedUser = user;
      this.viewingUserDetails = true;
      this.showPredictiveResults = false;
      this.searchQuery = ''; // Limpia el input
      const userDetailsUrl = `${window.location.origin}/user-details/${user.matricula}`;
      window.open(userDetailsUrl, '_blank');
    },
    closeOverlay() {
      this.searchQuery = '';
      this.showPredictiveResults = false;
    }
  },
};
</script>

<style scoped lang="sass">
  input
    width: 100%
    max-width: 400px
    padding: 10px 15px
    margin: 15px 0 5px 0
    border: none
    box-shadow: 5px 5px 5px rgba(0,0,0,0.3)
    transition: all 0.2s
    &:hover
      box-shadow: 5px 5px 10px rgba(0,0,0,0.6)    
    
  .overlay 
    position: fixed
    top: 0
    left: 0
    width: 100vw
    height: 100vh
    background-color: rgba(0, 0, 0, 0.5)
    z-index: 9
    transition: opacity 0.2s ease-in-out
    opacity: 1
  
  .overlay-hide 
    opacity: 0
    pointer-events: none
  
  .search-box 
    position: relative
    width: 100%
    max-width: 400px
    margin: -20px auto 0 auto
    z-index: 10
  
  .search-box ul 
    position: absolute
    width: 100%
    list-style-type: none
    padding: 0
    box-shadow: 0 0 10px rgba(155, 155, 155, 0.5)
    border-radius: 5px
    overflow: hidden
    z-index: 10
  
  .search-box li 
    cursor: pointer
    background: #fff
    padding: 10px 20px
    border-bottom: 1px solid #ccc
    font-weight: bold
    // font-size: 0.7em
    text-align: left
    transition: all 0.2s
    &:hover
      background: #e5e5e5

    span
      display: block
      font-weight: lighter
      font-size: 0.8em

  .no-results
    padding: 30px
    width: 100%
    text-align: center
    background: #fff
    position: absolute
    box-shadow: 0 0 10px rgba(155, 155, 155, 0.5)
    border-radius: 5px
    overflow: hidden
    z-index: 10
</style>
